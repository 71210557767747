<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">活动信息列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 下拉框  导出按钮部分 -->
                <div class="out_sel_btn">
                    <div class="sel_inp">
                        <el-select v-model="campus_id" placeholder="校区" clearable @change="initData" size="mini">
                            <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name" :value="item.uid">
                            </el-option>
                        </el-select>
                        <el-input placeholder="请输入活动名称" v-model="search_inp" clearable @change="initData" size="mini">
                        </el-input>
                    </div>
                    <el-button type="primary" @click="handle_out">导出</el-button>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table :data="tableData" tooltip-effect="dark" style="width: 100%"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column prop="uid" label="ID">
                        </el-table-column>
                        <el-table-column prop="name" label="活动名称">
                        </el-table-column>
                        <el-table-column prop="price" label="价格">
                        </el-table-column>
                        <el-table-column prop="closing_date" label="活动截止日期">
                        </el-table-column>
                        <el-table-column prop="people_count" label="报名人数">
                        </el-table-column>
                        <el-table-column prop="price_info" label="应收/实收">
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
        </div>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getInforApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 分页
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 搜索下拉框 校区
            campus_arr: [],
            campus_id: '',
            //  表格数据
            tableData: [],
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getInforApi({
                page: this.page,   //当前页
                name: this.search_inp,  //校区名称
                campus_id: this.campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    this.campus_arr = res.campus;  //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 导出数据
        handle_out () {
            window.location.href = "https://education.ztxinde.com/active_info_export?name=" + this.search_inp + '&campus_id=' + this.campus_id + '&auth_userid=' + this.user_id + '&page=' + this.page
        },
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 下拉框  导出按钮部分
        .out_sel_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 下拉框部分
            .sel_inp {
                margin-top: 15px;
                display: flex;
                .el-select {
                    width: 140px;
                    height: 30px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    ::v-deep.el-input__inner {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-select__caret {
                            line-height: 30px;
                        }
                        ::v-deep.el-input__icon {
                            line-height: 30px;
                        }
                    }
                }
                .el-input {
                    width: 155px;
                    height: 30px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left: 0px;
                    ::v-deep.el-input__inner {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                    }
                }
            }
            .el-button {
                width: 80px;
                height: 30px;
                line-height: 4px;
                margin-right: 8px;
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
}
</style>